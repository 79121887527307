.Header {
  background: #031013 !important;
  border: 2px solid #0D3141 !important;
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;
}

.Header .nav-link {
  color: rgb(255, 255, 255);
}

.Header .navbar-toggler {
  border-color: #00FF66 !important;
}

.Header .navbar-toggler span {
  filter: invert(1);
}

.Header .login-btn {
  background: none !important;
  border: 1px solid #00FF66 !important;
  color: #00FF66 !important;
}

.main-btn {
  background: #00FF66 !important;
  color: #031013 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  box-shadow: 0 0 0 0 #00FF66;
}

.slogan-section .slogan-title {
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: bold;
  color: #00FF66 !important;
}

.slogan-section .slogan-text {
  color: #fafafa;
  font-weight: 500;
  font-size: 1.5rem;
}

.sub-slogan-title {
  font-size: 3.5rem;
  font-weight: bold;
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  color: #00FF66 !important;
}

.trust-section img:hover {
  box-shadow: 0 0 35px 0 #00FF66;
    background: #02803a;
    backdrop-filter: blur(100px);
}

/* Main Card Style */

.main-card .card-title {
  font-size: 24px;
  font-weight: bold;
}

.main-card .card-text {
  font-size: 16px;
  text-align: justify;
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

.main-card.card {
  background: #0513177c !important;
  padding: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
  color: white !important;
}


.main-card.card::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(var(--rotate), #5ddcff, #3ce37c 43%, #00FF66);
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -2%;
  animation: spin 2.5s linear infinite;
}

.main-card.card::after {
  position: absolute;
  content: "";
  /* top: calc(var(--card-height) / 6); */
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(100px);
  background-image: linear-gradient(var(--rotate), #3ce37c, #00FF66 43%, #5ddcff);
  opacity: 1;
  transition: opacity .5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }

  100% {
    --rotate: 360deg;
  }
}



.faq-card {
  background: #0513177c !important;
  border: 1px solid #0D3141 !important;
  backdrop-filter: blur(50px);
}

.code-editor-windows {
  border: 1px solid #0D3141;
  border-radius: 5px;
  box-shadow: 0 0 30px 0 #00FF665c;
}

.code-editor-windows .code-editor-window-title {
  color: white;
  font-size: 12px;
  border-bottom: 1px solid #0D3141;
  position: relative;
  background: #031013;
}

.code-editor-windows .code-editor-window-title .windows-controller {
  position: absolute;
  right: 3%;
  color: rgba(255, 255, 255, 0.63);
}

.monaco-editor .margin {
  border-right: 1px solid #0D3141;
  background: #031013 !important;
}

.monaco-editor,
.monaco-editor-background {
  border-right: 1px solid #0D3141;
  background: #031013 !important;
}

.minimap-decorations-layer {
  background: #03101342 !important;
}

.faq-section .accordion .accordion-item {
  background: none;
  color: white;
  border: none;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.accordion-item:first-of-type .accordion-button:focus,
.accordion-item:last-of-type .accordion-button:focus {
  box-shadow: none;
}

.accordion-item .accordion-button {
  background: none !important;
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #0D3141 !important;
  font-size: 20px;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  font-weight: bold;
}

.accordion-item .accordion-body {
  border-top-color: red;
}

.accordion-button::after {
  background-image: none !important;
  content: "+" !important;
  text-align: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "-" !important;
  text-align: center;
  transform: none !important;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  box-shadow: none !important;
}

.register-card {
  border-radius: 10px;
  border: 1px solid #0D3141 !important;
  background: none !important;
  background-image: linear-gradient(180deg, rgb(39 204 184) 0%, rgba(5, 19, 23, 0.00) 100%) !important;
  backdrop-filter: blur(2.5px);
}

.register-card .card-title {
  font-size: 36px;
  font-weight: bold;
  color: white;
}

.Footer .copyright {
  font-size: 16px;
  color: #00FF66;
}

.Header .nav-link.active,
.Header .nav-link:hover {
  color: white !important;
}






.bg-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  top: -10rem;
}



.frame {
  width: 700px;
  height: 700px;
  position: absolute;
  border-radius: 50px;
  border: 5px solid #00FF66;
  box-shadow: 0 0 10px #00FF66,
    0 0 30px #00FF66;
  animation: zooming 4s linear infinite alternate;
}

.frame:nth-child(1) {
  transform: rotate(40deg) translateY(0px) translateZ(0px);
  opacity: 1;
  animation-delay: -1s;
}

.frame:nth-child(2) {
  transform: rotate(40deg) translateY(50px) translateZ(-10px);
  opacity: 0.9;
  animation-delay: -1.3s;
}

.frame:nth-child(3) {
  transform: rotate(40deg) translateY(100px) translateZ(-20px);
  opacity: 0.85;
  animation-delay: -1.5s;
}

.frame:nth-child(4) {
  transform: rotate(40deg) translateY(150px) translateZ(-30px);
  opacity: 0.8;
  animation-delay: -1.7s;
}

.frame:nth-child(5) {
  transform: rotate(40deg) translateY(200px) translateZ(-40px);
  opacity: 0.75;
  animation-delay: -1.9s;
}

.frame:nth-child(6) {
  transform: rotate(40deg) translateY(250px) translateZ(-50px);
  opacity: 0.7;
  animation-delay: -2.1s;
}

.frame:nth-child(7) {
  transform: rotate(40deg) translateY(300px) translateZ(-60px);
  opacity: 0.65;
  animation-delay: -2.3s;
}

.frame:nth-child(8) {
  transform: rotate(40deg) translateY(350px) translateZ(-70px);
  opacity: 0.6;
  animation-delay: -2.5s;
}

.frame:nth-child(9) {
  transform: rotate(40deg) translateY(400px) translateZ(-80px);
  opacity: 0.5;
  animation-delay: -2.7s;
}

.frame:nth-child(10) {
  transform: rotate(40deg) translateY(450px) translateZ(-90px);
  opacity: 0.4;
  animation-delay: -2.9s;
}

.frame:nth-child(11) {
  transform: rotate(40deg) translateY(500px) translateZ(-100px);
  opacity: 0.3;
  animation-delay: -3.1s;
}

.frame:nth-child(12) {
  transform: rotate(40deg) translateY(550px) translateZ(-110px);
  opacity: 0.2;
  animation-delay: -3.3s;
}

.frame:nth-child(13) {
  transform: rotate(40deg) translateY(600px) translateZ(-120px);
  opacity: 0.1;
  animation-delay: -3.5s;
}

.frame:nth-child(14) {
  transform: rotate(40deg) translateY(650px) translateZ(-130px);
  opacity: 0.05;
  animation-delay: -3.7s;
}

@keyframes zooming {
  0% {
    transform: scale(0.2) rotate(40deg);
    opacity: 1;
  }

  100% {
    transform: scale(1) rotate(180deg);
    opacity: 0;
  }
}


.news-card {
  background: none !important;
  border: none !important;
  color: white !important;
}

.news-card .card-title {
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  color: #00FF66 !important;
}

.news-card .card-text {
  font-size: 16px !important;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
}

.news-card:hover {
  background: #27ccb94b !important;
}

.news-card .card-img {
  object-fit: cover;
}

/* Banner Animation Style start */
.BannerAnimation .banner-animation-1 {
  position: absolute;
  bottom: 25%;
  left: 0%;
}

.BannerAnimation .banner-animation-2 {
  position: absolute;
  bottom: 65%;
  left: 5%;
}

.BannerAnimation .banner-animation-3 {
  position: absolute;
  bottom: 90%;
  left: 25%;
}

.BannerAnimation .banner-animation-4 {
  position: absolute;
  bottom: 90%;
  right: 25%;
}

.BannerAnimation .banner-animation-5 {
  position: absolute;
  bottom: 65%;
  right: 5%;
}

.BannerAnimation .banner-animation-6 {
  position: absolute;
  bottom: 25%;
  right: 0%;
}

.BannerAnimation .banner-animation-1 {
  animation-name: floating1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.BannerAnimation .banner-animation-2 {
  animation-name: floating2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.BannerAnimation .banner-animation-3 {
  animation-name: floating3;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.BannerAnimation .banner-animation-4 {
  animation-name: floating4;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.BannerAnimation .banner-animation-5 {
  animation-name: floating5;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.BannerAnimation .banner-animation-6 {
  animation-name: floating6;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating1 {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating2 {
  from {
    transform: translate(0, 15px);
  }

  65% {
    transform: translate(0, 0px);
  }

  to {
    transform: translate(0, 15px);
  }
}

@keyframes floating3 {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating4 {
  from {
    transform: translate(0, 15px);
  }

  65% {
    transform: translate(0, 0px);
  }

  to {
    transform: translate(0, 15px);
  }
}

@keyframes floating5 {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating6 {
  from {
    transform: translate(0, 15px);
  }

  65% {
    transform: translate(0, 0px);
  }

  to {
    transform: translate(0, 15px);
  }
}

/* Banner Animation Style end */

.main-card .main-card-image {
  width: 160px;
}

/* Workflow section style */
.workflow-section {
  background-image: url('../src/src/assets/images/banner/workflow.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.workflow-section .carousel-indicators {
  display: none;
}

.workflow-section .workflow-banner .workflow-banner-left,
.step-number {
  font-size: 24px;
  font-weight: bold;
  color: #00FF66;
}

.workflow-section .workflow-banner .workflow-banner-left,
.step-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.workflow-section .workflow-banner .workflow-banner-left,
.step-subtitle {
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.workflow-section .workflow-banner .workflow-banner-right .step-title {
  font-size: 128px;
  font-weight: bold;
  color: #00ff6634;
  text-align: end;
}

/* Codebase section style */
.codebase-section {
  overflow-x: clip;
}

/* Main Card Section */

.main-card-section .main-card .card-img{
  position: absolute;
  top: -5rem;
  right: 25%;
}

.main-card-section .use-case-list:nth-child(1) .main-card .card-img{
  animation-name: floating1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.main-card-section .use-case-list:nth-child(2) .main-card .card-img{
  animation-name: floating2;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.main-card-section .use-case-list:nth-child(3) .main-card .card-img{
  animation-name: floating3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes main-floating1 {
  from {
    transform: translate(0, 100px);
  }

  65% {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0, -0px);
  }
}
@keyframes main-floating2 {
  from {
    transform: translate(15, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(15, -0px);
  }
}
@keyframes main-floating3 {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}


.carousel-control-next, .carousel-control-prev, 
.carousel-control-next, .carousel-control-next  {
  display: none !important;
}


.modal-content {
  background-color: #031013 !important;
  border: 1px solid #00FF66 !important;
  box-shadow: 0 0 20px 0 #00FF66 !important;
  color: white !important;
}

.modal-content input, 
.modal-content input:focus, 
.modal-content textarea:focus,
.modal-content textarea {
  color: white !important;
  background-color: #031013;
}

.modal-content input::placeholder {
  color: white;
  opacity: 0.5; /* Firefox */
}

.modal-content input:focus {
  box-shadow: none !important;
  outline: none;
}

.modal-header .btn-close {
  filter: invert(1);
}